import React, {useEffect, useState} from 'react'
import {StringParam, useQueryParam, QueryParamProvider } from "use-query-params";
import {WindowHistoryAdapter} from "use-query-params/adapters/window";
import {
    PayPalScriptProvider,
    PayPalCardFieldsProvider,
    PayPalNameField,
    PayPalNumberField,
    PayPalExpiryField,
    PayPalCVVField,
    PayPalButtons,
    usePayPalCardFields,
} from "@paypal/react-paypal-js";
import olionLogo from "./assets/olion-logo.png";
import './assets/css/index.css';

export default function App() {
    return (
        <div className="bg-gray-50 dark:bg-gray-900 h-screen">
            <QueryParamProvider adapter={WindowHistoryAdapter} options={{}}>
                <AppImpl/>
            </QueryParamProvider>
        </div>
    )
}


function AppImpl() {
    const [token] = useQueryParam("t", StringParam);
    const [paymentIntent, setPaymentIntent] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(!token) {
            setError("Token not found")
            return
        }

        fetch(`/api/getPaymentIntent?token=${token}`).then(response => {
            return response.json();
        }).then(data => {
            setPaymentIntent(data)
            setLoading(false)
        }).catch(err => {
            setError(err.message)
            setLoading(false)
        })

        // setPaymentIntent({
        //     id: "123",
        //     createdAt: "2021-09-01T00:00:00Z",
        //     updatedAt: "2021-09-01T00:00:00Z",
        //     atomAction: "CREDITS",
        //     customerId: "123",
        //     customerShopId: "123",
        //     amount: 100,
        //     currency: "USD",
        //     status: "created",
        //     items: [
        //         {
        //             name: "Top Up",
        //             quantity: 1,
        //             price: 100,
        //             thumbnail: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAAXNSR0IArs4c6QAAAHRJREFUGFcBaQCW/wGGF63/M1prADumtQAxpfwAqatpAAEPvnn/OxJRACU/fwBO/PwAE80pAAHAW5T/ZU0bAIiCIQAmaiIAJtrCAAEbR1D/mp4fALsqlABrk8QALK/SAAFvZ9j/ae0JAEG6igCYDMoAzPO5AAi1KAKVK7VxAAAAAElFTkSuQmCC"
        //         }
        //     ],
        //     returnUrl: "http://localhost:3000/payment/success",
        //     cancelUrl: "http://localhost:3000/payment/cancel",
        //     errorUrl: "http://localhost:3000/payment/error",
        //     paypalClientId: "AUrJJYzj-jCLPFVI7yGU_FF7s5iBRSOTcW1AaHGKdMWIlxS2kOVOgEVlTQJK4Tj4mdWH3rAJXZNJ-62U",
        //     billingAddress: {
        //         name: "John Doe",
        //         addressLine1: "123 Main St",
        //         addressLine2: "Apt 2",
        //         adminArea1: "San Jose",
        //         adminArea2: "CA",
        //         postalCode: "95131",
        //         countryCode: "US",
        //     }
        // })
        // setLoading(false)
    }, [token]);

    async function onApprove(data) {
        return fetch("/api/capturePaymentOrder", {
            body: JSON.stringify({
                paymentIntentId: paymentIntent.id,
                approveData: data,
            }),
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
        })
            .then((res) => res.json())
            .then((data) => {
                if(paymentIntent.returnUrl) {
                    window.location.href = paymentIntent.returnUrl;
                }
            });
    }

    function onCancel(data) {
        fetch("/api/updatePaymentIntent", {
            body: JSON.stringify({
                paymentIntentId: paymentIntent.id,
                status: "CANCELLED",
                data: data,
            }),
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
        })
            .then((res) => res.json())
            .then((data) => {
                if(paymentIntent.cancelUrl) {
                    window.location.href = paymentIntent.cancelUrl;
                }
            });
    }

    function onError(data) {
        fetch("/api/updatePaymentIntent", {
            body: JSON.stringify({
                paymentIntentId: paymentIntent.id,
                status: "FAILED",
                data: data,
            }),
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
        })
            .then((res) => res.json())
            .then((data) => {
                if(paymentIntent.errorUrl) {
                    window.location.href = paymentIntent.errorUrl + "?error=" + data.message;
                }
            });
    }

    if(!token) {
        return (
            <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                <h4>Token not found</h4>
            </div>
        )
    }

    return (
        <section className="bg-gray-50 dark:bg-gray-900 py-10">
            <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto lg:py-0 mw-sceen h-full">
                <div
                    className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-lg min-h-[790px] xl:p-0 dark:bg-gray-800 dark:border-gray-700">
                    <div className="p-6 space-y-4 md:space-y-6 sm:p-8 flex flex-col h-full">
                        <div className="block items-center mb-4 text-2xl font-semibold text-gray-900 dark:text-white">
                            <img className="h-52 mx-auto" src={olionLogo} alt="logo"/>
                        </div>
                        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white text-center">
                            Complete your purchase
                        </h1>

                        {loading &&
                            <div className="flex flex-col h-full w-full justify-center items-center">
                                <div role="status">
                                    <svg aria-hidden="true"
                                         className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gold-600"
                                         viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"/>
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"/>
                                    </svg>
                                    <span className="sr-only">Loading...</span>
                                </div>
                            </div>
                        }
                        {(error && !loading) && <h4>Error: {error}</h4>}
                        {(!error && !loading) && (
                            <div className="flex flex-col space-y-6">
                                {paymentIntent && (
                                    <>
                                        <ItemDetails paymentIntent={paymentIntent}/>
                                        <PaypalForm
                                            paymentIntent={paymentIntent}
                                            onApprove={onApprove}
                                            onError={onError}
                                            onCancel={onCancel}
                                        />
                                    </>
                                )}
                                {!paymentIntent && (
                                    <div className="flex flex-col items-center justify-center">
                                        <h4 className="text-center">Ops! Looks like you are here by mistake. Please go back and try again.</h4>
                                    </div>
                                )}

                            </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
    );
}

function ItemDetails({paymentIntent}) {
    return (
        <div className="flex flex-col space-y-4 rounded-lg border border-gray-100 bg-gray-50 p-4 dark:border-gray-700 dark:bg-gray-800">
            <div className="flex flex-row items-center justify-between">
                <h2 className="text-lg text-gray-900 dark:text-white">Order Summary</h2>
            </div>
            <div className="flex flex-col space-y-4">
                {paymentIntent.items.map((item, index) => (
                    <div key={index} className="flex flex-row items-center justify-between">
                        <div className="flex flex-row items-center space-x-4">
                            {item.thumbnail && (
                                <img src={item.thumbnail} alt={item.name}
                                     className="w-12 h-12 object-cover rounded-lg"/>
                            )}
                            <div className="flex flex-col">
                                <h3 className="text-sm font-semibold text-gray-900 dark:text-white">{item.name}</h3>
                                <p className="text-xs text-gray-500 dark:text-gray-400">{item.quantity} x {formatPrice(item.price)}</p>
                            </div>
                        </div>
                        <p className="text-sm font-semibold text-gray-900 dark:text-white">{formatPrice(item.price)}</p>
                    </div>
                ))}
            </div>
            <div className="flex flex-row items-center justify-between border-t border-gray-200 pt-2 dark:border-gray-700">
                <h2 className="text-lg font-semibold text-gray-900 dark:text-white">Total</h2>
                <p className="text-lg font-semibold text-gray-900 dark:text-white">{formatPrice(paymentIntent.amount)}</p>
            </div>
        </div>
    )
}

function PaypalForm(props) {
    const {paymentIntent, onApprove, onCancel, onError} = props
    const [createOrderData, setCreateOrderData] = useState(null)
    const [isPaying, setIsPaying] = useState(false);

    if(!paymentIntent || !paymentIntent.paymentClientId) {
        return "";
    }

    function onCreateOrder() {
        return fetch("/api/createPaymentOrder", {
            body: JSON.stringify({
                paymentIntentId: paymentIntent.id,
            }),
            headers: {
                "Content-Type": "application/json",
            },
            method: "POST",
        })
            .then((res) => res.json())
            .then((data) => {
                setCreateOrderData(data);
                return data.id;
        });
    }

    const fieldsStyle = {
        height: "43px",
        input: {
            border: "1px solid rgb(209 213 219/1)",
            borderRadius: ".5rem",
            boxShadow: "none",
            boxSizing: "border-box",
            background: "#ffffff",
            // fontFamily: "Montserrat",
            fontSize: ".875rem",
            lineHeight: "1.25rem",
            padding: ".625rem",
            width: "100%",
            height: "43px",
            transition: "border-color .15s ease-in-out,box-shadow .15s ease-in-out",
        },
        ':focus': {
            'boxShadow': 'none',
            'border': "1px solid #ffbf00",
        },
    };

    return (
        <PayPalScriptProvider
            options={{
                // eslint-disable-next-line no-undef
                clientId: paymentIntent.paymentClientId,
                // eslint-disable-next-line no-undef
                currency: paymentIntent.currency,
                dataPageType: "checkout",
                intent: "capture",
                components: "card-fields,buttons"
            }}
        >
            <PayPalButtons
                style={{
                    color: "silver",
                    disableMaxWidth: true,
                    height: 40,
                    label: "pay",
                    layout: "vertical",
                    shape: "pill",
                }}
                fundingSource="paypal"
                createOrder={onCreateOrder}
                onApprove={onApprove}
                onCancel={onCancel}
                onError={onError}
            />
            <div style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 0
            }}>
                <div style={{borderBottom: "1px solid lightgray", width: "100%"}}/>
                <div style={{padding: "0 10px", color: "darkgray"}}>or</div>
                <div style={{borderBottom: "1px solid lightgray", width: "100%"}}/>
            </div>
            <PayPalCardFieldsProvider
                createOrder={onCreateOrder}
                onApprove={onApprove}
                onError={onError}
            >
                <PayPalNameField style={fieldsStyle}/>
                <PayPalNumberField style={fieldsStyle}/>
                <PayPalExpiryField style={fieldsStyle}/>
                <PayPalCVVField style={fieldsStyle}/>

                <SubmitPayment
                    isPaying={isPaying}
                    setIsPaying={setIsPaying}
                    billingAddress={paymentIntent.billingAddress}
                />
            </PayPalCardFieldsProvider>
        </PayPalScriptProvider>
    )

    function SubmitPayment({ isPaying, setIsPaying, billingAddress }) {
        const { cardFieldsForm, fields } = usePayPalCardFields();

        const handleClick = async () => {
            if (!cardFieldsForm) {
                const childErrorMessage =
                    "Unable to find any child components in the <PayPalCardFieldsProvider />";

                throw new Error(childErrorMessage);
            }
            const formState = await cardFieldsForm.getState();

            if (!formState.isFormValid) {
                return alert("Please fill in all fields with valida data");
            }
            setIsPaying(true);

            cardFieldsForm.submit().catch((err) => {
                console.error(err);
                setIsPaying(false);
            });
        };

        return (
            <button type="submit" onClick={handleClick} disabled={isPaying}
                    className="flex flex-row justify-center items-center gap-2 w-full my-5 text-white bg-gold-500 hover:bg-gold-600 focus:ring-4 focus:outline-none focus:ring-gold-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-gold-600 dark:hover:bg-gold-700 dark:focus:ring-gold-800">

                {isPaying && (
                    <div className="flex flex-col h-full w-fit justify-center items-center">
                        <div role="status">
                            <svg aria-hidden="true"
                                 className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-gold-600"
                                 viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                    fill="currentColor"/>
                                <path
                                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                    fill="currentFill"/>
                            </svg>
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                )}
                {!isPaying && (
                    <span>Purchase</span>
                )}
            </button>
            );
        }
}

function formatPrice(rawNumber) {
    return Intl.NumberFormat("en-US", {currency: "USD", style: "currency"}).format(rawNumber);
}

